import * as React from "react";

import {
  Columns,
  Container,
  Footer,
  Level,
  Left,
  Right,
  Menu,
  MenuItem,
} from "../components/bulma";

import { Link } from "gatsby";

import { useLocation } from "@reach/router";
import { useMerchants } from "../hooks/useMerchants";

// import { useCategories } from "../hooks/useCategories";
// import slugify from "slugify";

// import facebook from "../images/social/facebook.svg";
// import instagram from "../images/social/instagram.svg";
// import twitter from "../images/social/twitter.svg";
// import linkedin from "../images/social/linkedin.svg";

// import appStore from "../images/AppStore.svg";
// import googlePlay from "../images/GooglePlay.svg";
// import finhub from "../img/finhub-logo.svg";

const Bottom = () => {
  const location = useLocation();

  // const categories = useCategories();
  const merchant = useMerchants().find(
    (merchant) => merchant.slug === location.pathname.split("/")[1]
  );

  return (
    <>
      {!merchant ? (
        <Footer color="light">
          <Container>
            <div className="p-6">
              <Columns mobile multiline>
                {/* <div className="column is-6-mobile">
              <Menu label="Donate for Ukraine">
                {categories.map(category => (
                  <MenuItem
                    key={category}
                    to={`/${slugify(category).toLowerCase()}`}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Menu>
            </div> */}

                {/* <div className="column is-6-mobile">
              <Menu label="ProUkraine">
                <MenuItem to="/war-in-ukraine">War in Ukraine</MenuItem>
                <MenuItem to="/ukraine-war-news">
                  Latest Updates from Ukraine
                </MenuItem>
                <MenuItem to="/about">About us</MenuItem>
                <MenuItem to="/mission">Mission</MenuItem>
                <MenuItem to="/blog">Blog</MenuItem>
                <MenuItem to="/faq">FAQ</MenuItem>
                <MenuItem to="/contact">Contact</MenuItem>
              </Menu>
            </div> */}
              </Columns>
            </div>

            {/* <Level>
        <Left>
          <p>
            <a title="facebook" href="https://www.facebook.com/paymentninja/">
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: "2em", height: "2em", marginRight: 15 }}
              />
            </a>
            <a title="twitter" href="https://twitter.com/PaymentNinja">
              <img
                src={twitter}
                alt="Twitter"
                style={{ width: "2em", height: "2em", marginRight: 15 }}
              />
            </a>
            <a
              title="linkedin"
              href="https://www.linkedin.com/company/payment-ninja/"
            >
              <img
                src={linkedin}
                alt="Twitter"
                style={{ width: "2em", height: "2em", marginRight: 15 }}
              />
            </a>
            <a
              title="instagram"
              href="https://www.instagram.com/payment.ninja/"
            >
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: "2em", height: "2em" }}
              />
            </a>
          </p>
        </Left>
        <Item>
          <Link className="navbar-item" to="/terms">
            Terms of service
          </Link>
          <Link className="navbar-item" to="/privacy">
            Privacy
          </Link>
        </Item>
        <Right>
          <p>
            <a
              title="App Store"
              href="https://apps.apple.com/us/app/payment-ninja/id1477896858"
            >
              <img src={appStore} alt="App Store" />
            </a>{" "}
            <a
              title="Google Play"
              href="https://play.google.com/store/apps/details?id=ninja.payment.merchant"
            >
              <img className="fas fa-lg" src={googlePlay} alt="Google Play" />
            </a>
          </p>
        </Right>
      </Level> */}

            <Level>
              <Left>
                <p className="has-text-left is-size-7">
                  <strong>© 2023 Open Commerce.</strong>
                  {/* A{" "}
                  <a
                    title="FinHub Global Ventures"
                    href="https://finhub.vc/"
                    target="_blanc"
                  >
                    FinHub Global Ventures company.
                  </a>{" "} */}
                  <br />
                  All rights reserved. All product names, trademarks and
                  registered trademarks are property of their respective owners.
                  <br />
                  All company, product and service names used in this website
                  are for identification purposes only.
                  <br />
                  Use of these names, trademarks and brands does not imply
                  endorsement.
                </p>
              </Left>

              <Right>
                <Link className="navbar-item" to="/terms-of-service">
                  Terms of service
                </Link>
                <Link className="navbar-item" to="/privacy-policy">
                  Privacy Policy
                </Link>
                {/* <div
              style={{
                width: "120px",
                display: "inline-block",
              }}
            >
              <a
                title="FinHub Global Ventures"
                href="https://finhub.vc/"
                target="_blanc"
              >
                <img src={finhub} alt="FinHub Global Ventures logo" />
              </a>
            </div> */}
              </Right>
            </Level>
          </Container>
        </Footer>
      ) : (
        <Footer color="light">
          <Container>
            <div className="p-6">
              <Columns mobile multiline>
                {/* <div className="column is-6-mobile">
              <Menu label="Donate for Ukraine">
                {categories.map(category => (
                  <MenuItem
                    key={category}
                    to={`/${slugify(category).toLowerCase()}`}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Menu>
            </div> */}

                {/* <div className="column is-6-mobile">
              <Menu label="ProUkraine">
                <MenuItem to="/war-in-ukraine">War in Ukraine</MenuItem>
                <MenuItem to="/ukraine-war-news">
                  Latest Updates from Ukraine
                </MenuItem>
                <MenuItem to="/about">About us</MenuItem>
                <MenuItem to="/mission">Mission</MenuItem>
                <MenuItem to="/blog">Blog</MenuItem>
                <MenuItem to="/faq">FAQ</MenuItem>
                <MenuItem to="/contact">Contact</MenuItem>
              </Menu>
            </div> */}
              </Columns>
            </div>

            {/* <Level>
        <Left>
          <p>
            <a title="facebook" href="https://www.facebook.com/paymentninja/">
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: "2em", height: "2em", marginRight: 15 }}
              />
            </a>
            <a title="twitter" href="https://twitter.com/PaymentNinja">
              <img
                src={twitter}
                alt="Twitter"
                style={{ width: "2em", height: "2em", marginRight: 15 }}
              />
            </a>
            <a
              title="linkedin"
              href="https://www.linkedin.com/company/payment-ninja/"
            >
              <img
                src={linkedin}
                alt="Twitter"
                style={{ width: "2em", height: "2em", marginRight: 15 }}
              />
            </a>
            <a
              title="instagram"
              href="https://www.instagram.com/payment.ninja/"
            >
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: "2em", height: "2em" }}
              />
            </a>
          </p>
        </Left>
        <Item>
          <Link className="navbar-item" to="/terms">
            Terms of service
          </Link>
          <Link className="navbar-item" to="/privacy">
            Privacy
          </Link>
        </Item>
        <Right>
          <p>
            <a
              title="App Store"
              href="https://apps.apple.com/us/app/payment-ninja/id1477896858"
            >
              <img src={appStore} alt="App Store" />
            </a>{" "}
            <a
              title="Google Play"
              href="https://play.google.com/store/apps/details?id=ninja.payment.merchant"
            >
              <img className="fas fa-lg" src={googlePlay} alt="Google Play" />
            </a>
          </p>
        </Right>
      </Level> */}

            <Level>
              <Left>
                <p className="has-text-left is-size-7">
                  <strong>© 2023 {merchant.name}.</strong>
                  <br />
                  All rights reserved. All product names, trademarks and
                  registered trademarks are property of their respective owners.
                  <br />
                  All company, product and service names used in this website
                  are for identification purposes only.
                  <br />
                  Use of these names, trademarks and brands does not imply
                  endorsement.
                </p>
              </Left>

              <Right>
                <Link className="navbar-item" to="/terms-of-service">
                  Terms of service
                </Link>
                <Link className="navbar-item" to="/privacy-policy">
                  Privacy Policy
                </Link>
                {/* <div
              style={{
                width: "120px",
                display: "inline-block",
              }}
            >
              <a
                title="FinHub Global Ventures"
                href="https://finhub.vc/"
                target="_blanc"
              >
                <img src={finhub} alt="FinHub Global Ventures logo" />
              </a>
            </div> */}
              </Right>
            </Level>
          </Container>
        </Footer>
      )}
    </>
  );
};

export default Bottom;
