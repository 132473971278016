import React, { useState, useEffect } from "react";

import { useStaticQuery, graphql, navigate } from "gatsby";

import axios from "axios";
import numeral from "numeral";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Button, Input } from "./bulma.js";
import AddressLookup from "../components/AddressLookup";

import useGlobal from "../store";
import { getTotal } from "../utils/getTotal";

import { GatsbyImage } from "gatsby-plugin-image";

import { useLocation } from "@reach/router";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

// const stripePromise = loadStripe(
//   "pk_live_51M8pnDKkuca8rfgcciK1SsSXXbkFFRQpTuSA7vazUl0cw6B7HvbUuNApJYZChJfng1HXNfOICHTtBE3nzlf89K50004yt3IZn7",
//   {
//     stripeAccount: "acct_1N8DJyQP4g98kSlQ",
//   }
// );

const Checkout = ({ modal, setModal }) => {
  const [globalState, globalActions] = useGlobal();

  const [step, setStep] = useState(0);

  const [total, setTotal] = useState();
  const [subtotal, setSubtotal] = useState();
  const [discount, setDiscount] = useState();

  const [loading, setLoading] = useState(false);

  const [stripeAccount, setStripeAccount] = useState("");
  const [stripePromise, setStripePromise] = useState();
  const [stripeClientSecret, setStripeClientSecret] = useState("");

  useEffect(() => {
    if (stripeAccount) {
      setStripePromise(
        loadStripe(
          // process.env.NODE_ENV !== "development"
          //   ? "pk_test_51M8pnDKkuca8rfgcZQppbV9BKIIarcctavJwIM6cuoX0JfNVDjWYJd19rClvUintU9qjkTkNAWvtrEbfJYqsmow800HKfKiX7M"
          //   : "pk_live_51M8pnDKkuca8rfgcciK1SsSXXbkFFRQpTuSA7vazUl0cw6B7HvbUuNApJYZChJfng1HXNfOICHTtBE3nzlf89K50004yt3IZn7",
          "pk_live_51M8pnDKkuca8rfgcciK1SsSXXbkFFRQpTuSA7vazUl0cw6B7HvbUuNApJYZChJfng1HXNfOICHTtBE3nzlf89K50004yt3IZn7",
          { stripeAccount }
        )
      );
    }
  }, [stripeAccount]);

  useEffect(() => {
    if (globalState.cart) {
      const { total, subtotal, discount } = getTotal(globalState);
      setTotal(total);
      setSubtotal(subtotal);
      setDiscount(discount);
    }
  }, [globalState]);

  return (
    <div
      className={`modal ${
        modal && "is-active"
      } modal-full-screen modal-fx-fadeInScale`}
    >
      <div className="modal-background" />

      {step === 0 && (
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Your Cart</p>
            <button
              className="delete is-medium"
              aria-label="close"
              onClick={() => setModal(false)}
            />
          </header>
          <section className="modal-card-body">
            <>
              {step === 0 && (
                <>
                  {globalState.cart.length > 0 ? (
                    <>
                      {globalState.cart
                        .sort((a, b) => {
                          const nameA = a.name.toUpperCase();
                          const nameB = b.name.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((item, index) => (
                          <nav key={index} className="level is-mobile">
                            {/* <div className="level-left">
                    <figure className="image is-48x48">
                      <GatsbyImage
                        image={item.product.images[0]}
                        alt={item.product.name}
                      />
                    </figure>
                  </div> */}
                            <div className="level-left has-text-weight-semibold">
                              {item.name}
                            </div>

                            <div className="level-right">
                              <div className="buttons has-addons are-small">
                                <button
                                  type="button"
                                  className="button is-light is-rounded"
                                  onClick={() =>
                                    globalActions.removeFromCart(item)
                                  }
                                >
                                  -
                                </button>
                                <button
                                  className="button is-light"
                                  type="button"
                                >
                                  {item.qty}
                                </button>
                                <button
                                  type="button"
                                  className="button is-light is-rounded"
                                  onClick={() => globalActions.addToCart(item)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </nav>
                        ))}
                      <hr />
                      <div className="level is-mobile">
                        <div className="level-right has-text-weight-semibold">
                          Subtotal
                        </div>
                        <div className="level-right has-text-weight-semibold">
                          {numeral(subtotal).format("$0,0.00")}
                        </div>
                      </div>
                      <div className="level is-mobile">
                        <div className="level-right has-text-weight-semibold">
                          Service Fee
                        </div>
                        <div className="level-right has-text-weight-semibold">
                          {numeral(subtotal * 0.01).format("$0,0.00")}
                        </div>
                      </div>
                      <div className="level is-mobile">
                        <div className="level-right has-text-weight-semibold">
                          Total
                        </div>
                        <div className="level-right has-text-weight-semibold">
                          {numeral(subtotal + subtotal * 0.01).format(
                            "$0,0.00"
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>Cart is empty</div>
                  )}
                </>
              )}
            </>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-black is-rounded"
              onClick={() => setStep(step + 1)}
              disabled={globalState.cart.length === 0}
            >
              Checkout
            </button>
            <button
              onClick={() => (step === 0 ? setModal(false) : setStep(step - 1))}
              className="button is-rounded"
            >
              Continue Shopping
            </button>
          </footer>
        </div>
      )}

      {/* DELIVERY DETAILS */}

      {step === 1 && (
        <div className="modal-card">
          <Formik
            initialValues={{
              fullName: "",
              phone: "",
              address: "",
              suite: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async ({ fullName, phone, address, suite }) => {
              setLoading(true);

              const orderProps = {
                order: {
                  items: globalState.cart.map((item) => ({
                    _variant: item.variants[0],
                    quantity: item.qty,
                  })),
                  type: "delivery",
                  customer: { fullName, phone, address, suite },
                },
              };

              try {
                const { data } = await axios.post(
                  "/.netlify/functions/createOrder",
                  {
                    orderProps,
                  }
                );

                setStripeAccount(data.stripeAccount);
                setStripeClientSecret(data.clientSecret);
                setStep(2);

                globalActions.resetCart();

                // navigate("/confirmation", {
                //   state: {
                //     type,
                //     address,
                //     location,
                //     phone,
                //     orderNumber: data.order.order_number,
                //     deliveryDate,
                //     timeslot,
                //     asap,
                //   },
                // });

                setLoading(false);
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <>
                <Form>
                  <header className="modal-card-head">
                    <p className="modal-card-title">Delivery Details</p>
                    <button
                      type="button"
                      className="delete is-medium"
                      aria-label="close"
                      onClick={() => setModal(false)}
                    />
                  </header>
                  <section className="modal-card-body">
                    <Field
                      name="fullName"
                      size="medium"
                      label="Full name"
                      component={Input}
                      placeholder="Full name"
                    />

                    <Field
                      size="medium"
                      label="Phone Number"
                      name="phone"
                      component={Input}
                      mask="(999) 999 9999"
                      placeholder="Phone number"
                    />

                    <Field
                      size="medium"
                      label="Delivery Address"
                      name="address"
                      component={AddressLookup}
                      placeholder="Address"
                    />

                    <Field
                      size="medium"
                      label="Suite (optional)"
                      name="suite"
                      component={Input}
                      placeholder="Appartment, suite, etc. (optional)"
                    />
                  </section>

                  <footer className="modal-card-foot">
                    <Button
                      color="black"
                      rounded
                      type="submit"
                      // disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Continue to Payment
                    </Button>

                    <button
                      onClick={() =>
                        step === 0 ? setModal(false) : setStep(step - 1)
                      }
                      className="button is-rounded ml-2"
                    >
                      Go Back
                    </button>
                  </footer>
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}

      {/* PAYMENT */}

      {step === 2 && (
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Payment</p>
            <button
              type="button"
              className="delete is-medium"
              aria-label="close"
              onClick={() => setModal(false)}
            />
          </header>

          <section className="modal-card-body">
            {stripeClientSecret && stripePromise && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: stripeClientSecret,
                  appearance: { theme: "stripe", locale: "auto" },
                }}
              >
                <CheckoutForm />
              </Elements>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const url =
    location.href.split("/")[0] +
    "//" +
    location.href.split("/")[1] +
    location.href.split("/")[2] +
    "/" +
    location.href.split("/")[3];

  console.log(url);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: url,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <footer className="modal-card-foot">
        <button
          disabled={!stripe}
          className={`button is-black is-rounded is-fullwidth is-medium ${
            isLoading && "is-loading"
          }`}
        >
          Pay now
        </button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
        {message && <div id="payment-message">{message}</div>}
      </footer>
    </form>
  );
};

export default Checkout;

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Please enter your full name!"),
  phone: Yup.string()
    .min(10, "Please enter your phone number!")
    .required("Please enter your phone number!"),
  address: Yup.string().required("Please enter delivery address!"),
});
