export const addToCart = (store, product) => {
  const exists = store.state.cart.find(
    ({ mongodb_id }) => mongodb_id === product.mongodb_id
  );

  if (!exists) {
    const cart = store.state.cart.concat([{ ...product, qty: 1 }]);
    store.setState({ cart });
  } else {
    const cart = store.state.cart.filter(
      ({ mongodb_id }) => mongodb_id !== product.mongodb_id
    );
    const newCart = cart.concat([{ ...product, qty: ++exists.qty }]);
    store.setState({ cart: newCart });
  }
};

export const removeFromCart = (store, product) => {
  const exists = store.state.cart.find(
    ({ mongodb_id }) => mongodb_id === product.mongodb_id
  );

  if (exists)
    if (exists.qty > 1) {
      const cart = store.state.cart.filter(
        ({ mongodb_id }) => mongodb_id !== product.mongodb_id
      );
      const newCart = cart.concat([{ ...product, qty: --exists.qty }]);
      store.setState({ cart: newCart });
    } else {
      const cart = store.state.cart.filter(
        ({ mongodb_id }) => mongodb_id !== product.mongodb_id
      );

      store.setState({ cart });
    }
};

export const resetCart = (store) => store.setState({ cart: [] });
