import React from "react";
import globalHook from "use-global-hook";

import * as actions from "./actions";

const initialState = {
  cart: [],
};

const useGlobal = globalHook(initialState, actions);

export default useGlobal;
