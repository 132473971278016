import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import axios from "axios";
import { Formik, Form, Field } from "formik";

import { FiHome } from "@react-icons/all-files/fi/FiHome";
import { FiGrid } from "@react-icons/all-files/fi/FiGrid";
import { FiShoppingCart } from "@react-icons/all-files/fi/FiShoppingCart";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage } from "gatsby-plugin-image";

// import slugify from "slugify";

import { Button, Input } from "./bulma.js";
import AddressLookup from "../components/AddressLookup";

import Checkout from "../components/Checkout";

// import { useCategories } from "../hooks/useCategories";
import { useMerchants } from "../hooks/useMerchants";

import useGlobal from "../store";

// import { Logo } from "./brand";

const Navbar = () => {
  const location = useLocation();
  const [globalState] = useGlobal();

  // const categories = useCategories();
  const merchant = useMerchants().find(
    (merchant) => merchant.slug === location.pathname.split("/")[1]
  );

  const [menu, setMenu] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Checkout modal={modal} setModal={setModal} />
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            {!merchant ? (
              <Link to="/" className="navbar-item" title="Logo">
                <p className="title is-4">Open</p>
              </Link>
            ) : (
              <Link
                to={`/${merchant.slug}`}
                className="navbar-item"
                title="Logo"
              >
                <p className="title is-4">{merchant.name}</p>
              </Link>
            )}

            {!merchant ? (
              <div
                className={`navbar-burger burger ${menu ? "is-active" : ""}`}
                data-target="navMenu"
                onClick={() => setMenu(!menu)}
              >
                <span />
                <span />
                <span />
              </div>
            ) : null}
          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${menu ? "is-active" : ""}`}
          >
            <div className="navbar-end has-text-centered is-size-5">
              {/* <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link has-text-weight-semibold">
                  How it works
                </a>

                <div className="navbar-dropdown">
                  {categories.map(category => (
                    <Link
                      key={category}
                      to={`/${slugify(category).toLowerCase()}`}
                      className="navbar-item is-size-5"
                      onClick={() => setMenu(false)}
                    >
                      {category}
                    </Link>
                  ))}
                </div>
              </div> */}

              {!merchant ? (
                <>
                  {/* <Link
                    className="navbar-item has-text-weight-semibold"
                    to="/about"
                    onClick={() => setMenu(false)}
                  >
                    About
                  </Link> */}
                  <Link
                    className="navbar-item has-text-weight-semibold"
                    to="/blog"
                    onClick={() => setMenu(false)}
                  >
                    Blog
                  </Link>
                </>
              ) : null}
              {/* <Link
                className="navbar-item has-text-weight-semibold"
                to="/contact"
              >
                Contact
              </Link> */}
            </div>
            <div className="navbar-end">
              {merchant ? (
                <div className="navbar-item">
                  <div className="buttons is-centered">
                    <button
                      disabled={globalState.cart.length === 0}
                      onClick={() => setModal(true)}
                      type="button"
                      className="button is-black is-rounded has-text-weight-semibold"
                    >
                      <span className="icon">
                        <FiShoppingCart size={20} />
                      </span>
                      <span className="ml-1">
                        Checkout{" "}
                        {globalState.cart.length > 0
                          ? `(${globalState.cart.length})`
                          : null}
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons is-centered">
                  <a
                    href="https://app.proukraine.org/"
                    className="button is-ghost has-text-white"
                  >
                    <strong>Partner with us</strong>
                  </a>
                  <a
                    href="https://app.proukraine.org/"
                    className="button is-ghost is-rounded has-text-white"
                  >
                    Sign in
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </nav>

      {/* BOTTOM NAVBAR */}

      {merchant ? (
        <nav
          className="navbar is-light is-fixed-bottom is-hidden-desktop"
          role="navigation"
          aria-label="main-navigation"
        >
          <nav className="level is-mobile">
            <div className="level-item has-text-centered">
              <div>
                <Link to={`/${merchant.slug}`} className="button is-light">
                  <span className="icon">
                    <FiHome size={32} />
                  </span>
                </Link>
                <p className="heading">Home</p>
              </div>
            </div>
            {merchant.products.length > 0 ? (
              <div className="level-item has-text-centered">
                <div>
                  <AnchorLink
                    to={`/${merchant.slug}#products`}
                    className="button is-light"
                  >
                    <FiGrid size={24} />
                  </AnchorLink>
                  <p className="heading">Products</p>
                </div>
              </div>
            ) : null}
            {/* <div className="level-item has-text-centered">
            <div>
              <button className="button is-light">
                <span className="icon">
                  <FiHome size={32} />
                </span>
              </button>
              <p className="heading">Home</p>
            </div>
          </div> */}
            <div className="level-item has-text-centered">
              <div>
                <button
                  className="button is-light"
                  onClick={() => setModal(true)}
                >
                  <span className="icon">
                    <FiShoppingCart size={32} />
                  </span>

                  {globalState.cart.length > 0 ? (
                    <span
                      className="tag is-danger is-rounded"
                      style={{
                        fontSize: 8,
                        position: "relative",
                        top: -10,
                        left: -10,
                      }}
                    >
                      {globalState.cart.length > 0
                        ? globalState.cart.length
                        : null}
                    </span>
                  ) : null}
                </button>
                <p className="heading">Checkout</p>
              </div>
            </div>
          </nav>
        </nav>
      ) : null}
    </div>
  );
};

export default Navbar;
