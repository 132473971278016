import { graphql, useStaticQuery } from "gatsby";
// import { getImage } from "gatsby-plugin-image";

// import { slug as createSlug } from "../utils/slug";

export const useMerchants = () => {
  const data = useStaticQuery(graphql`
    query Campaigns {
      allMongodbOpenMerchants {
        edges {
          node {
            id
            name
            slug
            description
            products
          }
        }
      }
      allCloudinaryMedia {
        nodes {
          publicId
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const merchants = data.allMongodbOpenMerchants.edges.flatMap(
    ({ node }) => node
  );
  // .map((campaign) => {
  //   const images = data.allCloudinaryMedia.nodes
  //     .filter((image) => campaign.pictures.includes(image.publicId))
  //     .map((image) => getImage(image));

  //   const path = createSlug(campaign);

  //   return { ...campaign, path, images };
  // });

  return merchants;
};
