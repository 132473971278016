import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";

export default (props) => {
  const { form, field, label, size } = props;

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDjaLPfTVAaEaOQG-Sn3l7SAsgqbA9e3mI",
    onPlaceSelected: (place) =>
      form.setFieldValue(field.name, place.formatted_address),
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  return (
    <div className="field">
      <label className={`label is-${size}`}>{label}</label>
      <input className={`input is-${size}`} ref={ref} {...props} />
      {form.touched[field.name] && form.errors[field.name] && (
        <p className="help is-danger">{form.errors[field.name]}</p>
      )}
    </div>
  );
};
