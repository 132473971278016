exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-index-jsx": () => import("./../../../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-merchant-js": () => import("./../../../src/templates/merchant.js" /* webpackChunkName: "component---src-templates-merchant-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

